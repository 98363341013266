import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  Footer,
  NumericInput,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { Create, Return } from "images/Goal";
import { Copy, Person, Remove, Retirar, Time } from "@images";
import Addicon from "@material-ui/icons/Add";

const FoundDepositDesktop = ({amount, goalName, fundName}) => {
  const classes = useStyles();
  return (
    <div className={` ${classes.container}`}>
      <div className={classes.subContainer}>
        <section>
          <div className={classes.headerLayout}>
            <div className={classes.IconMision}>
              <Addicon />
            </div>
            <div
              onClick={() => navigate(Route.investments)}
              className={classes.return}
            >
              <img src={Return} />
              Volver
            </div>
          </div>
        </section>
        <section className={classes.containerData}>
          <div className={classes.children}>
            <div className={classes.firstColumn}>
              <div className={classes.cardDeposit}>
                <div className="title">Deposita tu inversión en:</div>
                <div>
                  <span>Vector Capital Corredores de Bolsa Spa</span>
                  <img src={Copy} />
                </div>
                <div>
                  <span> 76.513.680-6</span> <img src={Copy} />
                </div>
                <div>
                  <span>Cuenta Corriente </span>
                </div>
                <div>
                  <span>Banco BICE</span> <img src={Copy} />
                </div>
                <div>
                  <span>01372491 </span> <img src={Copy} />
                </div>
                <div>
                  <span>hola@rokinapp.com</span> <img src={Copy} />
                </div>
                <ButtonOutlined
                  className="button"
                  onClick={() => navigate(Route.investments)}
                  text="Volver a Mis Inversiones"
                />
              </div>
              <div className={classes.advertence}>
                Recuerda que tenemos una alianza con Vector Corredora de Bolsa
                S.A, por lo que invertirás a través de ellos
              </div>
            </div>
            <div className={classes.lastColumn}>
              {/**<div className={classes.deposit}>
                <span>ó</span>
                <ButtonPrimary text="Deposita aquí" onClick={() => {}} />
              </div>**/}
              <div className={classes.invest}>
                Invertirás <strong>${amount}</strong> en
                <strong> {fundName} </strong>
                para el objetivo de "<strong>{goalName}</strong>"
              </div>
              <div className={classes.lastBox}>
                <div>
                  <img src={Person} />
                  <span>
                    Recuerda depositar desde una cuenta con el mismo RUT que te
                    registraste
                  </span>
                </div>
                <div>
                  <img src={Time} />
                  <span>
                    Tu inversión estará lista en aproximadamente 2 días hábiles
                  </span>
                </div>
                <div>
                  <img src={Remove} />
                  <span>
                    Revisa el estado de tus depósitos en “Mis inversiones”
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer className={`${classes.footer} `} />
    </div>
  );
};
export default FoundDepositDesktop;
const useStyles = makeStyles<Theme>(theme => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  subContainer: {
    display: "flex",
    marginBottom: "7rem",
  },
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    color: theme.palette.text.primary,
  },
  children: {
    display: "flex",
    marginTop: 59,
  },
  headerLayout: {
    display: "grid",
    gap: "2rem",
    alignItems: "center",
    paddingLeft: 106,
    paddingTop: 50,
    width: 200,
    justifyContent: "center",
    "& img": {
      width: 140,
      height: 50,
    },
  },
  IconMision: {
    display: "flex",
    justifyContent: "center",
    background: "linear-gradient(161deg, #8250ff -6%, #181331 117%)",
    height: 55,
    width: 55,
    alignItems: "center",
    borderRadius: "100%",
    boxShadow: "1px 1px 10px 0 #8250ff",
    "& svg": {
      width: 40,
      height: 40,
      color: "white",
    },
  },
  containerData: {
    display: "flex",
    gap: "2rem",
    marginLeft: "3rem",
    width: "100%",
    "& > div": {
      cursor: "pointer",
      width: "100%",
    },
  },
  return: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color:theme.palette.text.secondary,
    "& img": {
      width: 50,
      height: 50,
    },
  },
  cardDeposit: {
    gridArea: "cardDeposit",
    background: "white",
    boxShadow: "1px 1px 30px 0 grey",
    width: 480,
    height: 522,
    borderRadius: 30,
    padding: "33px 47px",
    "& > .title": {
      color: theme.palette.text.primary,
      fontSize: 24,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      paddingBottom: 20,
    },
    "& >div": {
      display: "flex",
      justifyContent: "space-between",
      lineHeight: 2.3,
      fontWeight: 500,
    },
    "& .button": {
      marginTop: 106,
    },
  },
  advertence: {
    gridArea: "advertence",
    width: 400,
    fontWeight: 600,
    marginTop: 73,
    marginLeft: 30,
  },
  deposit: {
    display: "flex",
    flexFlow: "column",
    gridArea: "deposit",
    background: "white",
    boxShadow: "1px 1px 30px 0 grey",
    width: 443,
    height: 196,
    borderRadius: 30,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    "& >span": {
      display: "block",
      fontWeight: "bold",
      fontSize: 24,
      paddingBottom: "1rem",
    },
    "& button": {
      fontSize: 20,
      fontWeight: 500,
      width: 250,
    },
  },
  invest: {
    gridArea: "invest",
    fontSize: 24,
    lineHeight: 1.33,
    width: 363,
    margin: "50px 35px",
    fontWeight: 300,
    "& strong": {
      fontWeight: "bold",
    },
  },
  lastBox: {
    gridArea: "lastBox",
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "-3px -3px 30px 0 grey",
    width: 443,
    height: 270,
    borderRadius: 30,
    padding: 30,
    display: "grid",
    "&>div": {
      "& img": {
        width: 32,
        height: 32,
      },
      display: "flex",
      fontWeight: 300,
      alignItems: "center",
      gap: 28,
      width: 370,
      color: theme.palette.text.secondary,
    },
  },
  firstColumn: {
    paddingRight: 65,
  },
}));
