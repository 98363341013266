import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  Footer,
  NumericInput,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { arrowGoal, Create, Return } from "images/Goal";
import { AddIcon, Copy, Person, Remove, Retirar, Time } from "@images";

const FoundDepositMobile = ({amount, goalName, fundName}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.container}`}>
      <section className={classes.header}>
        <img onClick={() => navigate(Route.investments)} src={arrowGoal} />
        <div className={classes.titleHeader}>Agregar Fondos</div>
        <img src={AddIcon} className={classes.addIcon} />
      </section>
      <div className={classes.invest}>
        Invertirás <strong>$ {amount}</strong> en
        <strong>{fundName}</strong>
        para el objetivo de "<strong>{goalName}</strong>"
      </div>
      <div className={classes.cardDeposit}>
        <div className="title">Deposita tu inversión en:</div>
        <div>
          <span>Vector Capital Corredores de Bolsa Spa</span>
          <img src={Copy} />
        </div>
        <div>
          <span> 76.513.680-6</span> <img src={Copy} />
        </div>
        <div>
          <span>Cuenta Corriente </span>
        </div>
        <div>
          <span>Banco BICE</span> <img src={Copy} />
        </div>
        <div>
          <span>01372491 </span> <img src={Copy} />
        </div>
        <div>
          <span>hola@rokinapp.com</span> <img src={Copy} />
        </div>
        <ButtonOutlined
          className="button"
          onClick={() => {}}
          text="Volver a Mis Inversiones"
        />
      </div>
      {/*<div className={classes.deposit}>
        <span>ó</span>
        <ButtonPrimary text="Deposita aquí" onClick={() => {}} />
        <span>A través de</span>
      </div>*/}
      <div className={classes.lastBox}>
        <div>
          <img src={Person} />
          <span>
            Recuerda depositar desde una cuenta con el mismo RUT que te
            registraste
          </span>
        </div>
        <div>
          <img src={Time} />
          <span>
            Tu inversión estará lista en aproximadamente 2 días hábiles
          </span>
        </div>
        <div>
          <img src={Remove} />
          <span>Revisa el estado de tus depósitos en “Mis inversiones”</span>
        </div>
      </div>
      <div className={classes.bottom}>
        Recuerda que tenemos una alianza con Vector Corredora de Bolsa S.A, por
        lo que invertirás a través de ellos
      </div>
      <Footer />
    </div>
  );
};
export default FoundDepositMobile;
const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    fontFamily: "Montserrat",
  },
  invest: {
    fontSize: 14,
    color: theme.palette.text.primary,
    width: 350,
    padding: 20,
    margin: "0 auto",
  },
  cardDeposit: {
    gridArea: "cardDeposit",
    background: "white",
    boxShadow: "1px 1px 30px 0 grey",
    width: 350,
    height: 440,
    borderRadius: 30,
    padding: "20x 30px",
    margin: " 0 auto",
    "& > .title": {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      paddingTop: "1rem",
    },
    "& >div": {
      display: "flex",
      justifyContent: "space-between",
      width: 300,
      fontWeight: 500,
      margin: "0 auto",
      padding: "10px 0",
      "& >span": {
        width: 200,
        color: theme.palette.text.secondary,
      },
    },
    "& .button": {
      width: 255,
      marginTop: "1rem",
      display: "flex",
      margin: "0 auto",
    },
  },
  deposit: {
    display: "flex",
    flexFlow: "column",
    gridArea: "deposit",
    background: "white",
    boxShadow: "1px 1px 30px 0 grey",
    width: 350,
    height: 150,
    borderRadius: 30,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    marginTop: "2rem",
    "& >span": {
      display: "block",
      fontWeight: "bold",
      fontSize: 24,
      paddingBottom: "0.5rem",
    },
    "&>span:last-child": {
      fontSize: 14,
      display: "block",
      fontWeight: 400,
      paddingTop: "1rem",
      color: theme.palette.text.secondary,
    },
    "& button": {
      fontWeight: 500,
      width: 250,
    },
  },
  addIcon: {
    width: "120px!important",
    position: "absolute",
    right: 20,
    top: 10,
  },
  lastBox: {
    gridArea: "lastBox",
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "-3px -3px 30px 0 grey",
    width: 350,
    height: 270,
    borderRadius: 30,
    padding: "10px 20px",
    marginTop: "2rem",
    marginBottom: "2rem",
    margin: "0 auto",
    display: "grid",
    fontSize: 14,
    "&>div": {
      "& img": {
        width: 32,
        height: 32,
      },
      display: "flex",
      fontWeight: 300,
      alignItems: "center",
      width: 300,
      color: theme.palette.text.secondary,
      "& >span": {
        paddingLeft: "1rem",
      },
    },
  },
  header: {
    background:
      "linear-gradient(161deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)",
    paddingTop: 30,
    borderRadius: "0 0 30px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingBottom: 15,
    marginBottom: 20,
    "& >img": {
      width: 30,
      marginLeft: "1rem",
    },
    "& >div": {
      paddingLeft: "3rem",
    },
  },
  titleHeader: {
    color: "white",
    fontSize: 20,
  },
  bottom: {
    width: 300,
    margin: "0 auto",
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
}));
