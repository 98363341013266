import React, { useEffect, useState } from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { ButtonOutlined, ButtonPrimary, Footer } from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { Create, Return } from "images/Goal";
import { Copy, Person, Time, Remove } from "@images";
import Addicon from "@material-ui/icons/Add";
import FoundDepositDesktop from "@components/Structural/Founds/FoundDeposit";
import FoundDepositMobile from "@components/Structural/Founds/FoundDepositMobile";
import { useAuth, useURLParams } from "@hooks";
import { useGoals, useUserInvestments, usePortfolios } from "@apollo";

const Founds = () => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const [params, setParams] = useState(undefined);
  const { goals } = useGoals();
  const [goalName, setGoalName] = useState(undefined);
  const [fundName, setFundName] = useState(undefined);
  const [amount, setAmount] = useState(undefined);
  const { portfolios, loading: loadingPortfolios } = usePortfolios();
  const { investments, loading: loadingInvestments } = useUserInvestments();

  useEffect(() => {
    setParams(useURLParams());
  }, []);

  useEffect(() => {
    if (params !== undefined) {
      const goalId = params.filter((obj) => obj.goalId)[0].goalId;
      const goal = goals.filter((obj) => obj.id == goalId)[0];
      if (goal !== undefined) {
        setGoalName(goal.name);
      }
      setAmount(
        parseInt(params.filter((obj) => obj.amount)[0].amount).toLocaleString(
          "es"
        )
      );
      const fundId = params.filter((obj) => obj.fundId)[0].fundId;
      const fund = [...portfolios, ...investments].filter(
        (obj) => obj.id == fundId
      );
      if (fund.length != 0) {
        if (fund[0].__typename == "Portfolio") {
          setFundName(fund[0].name);
        } else {
          setFundName(fund[0].fund.name);
        }
      }
    }
  }, [goals, params]);

  return !isMobile ? (
    <FoundDepositDesktop
      amount={amount}
      goalName={goalName}
      fundName={fundName}
    />
  ) : (
    <FoundDepositMobile
      amount={amount}
      goalName={goalName}
      fundName={fundName}
    />
  );
};
export default Founds;
const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  subContainer: {
    display: "flex",
    marginBottom: "7rem",
  },
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
    fontFamily: "Montserrat",
    color: theme.palette.text.primary,
  },
  children: {
    display: "flex",
    marginTop: 59,
  },
  headerLayout: {
    display: "grid",
    gap: "2rem",
    alignItems: "center",
    paddingLeft: 106,
    paddingTop: 50,
    width: 200,
    justifyContent: "center",
    "& img": {
      width: 140,
      height: 50,
    },
  },
  IconMision: {
    display: "flex",
    justifyContent: "center",
    background: "linear-gradient(161deg, #8250ff -6%, #181331 117%)",
    height: 55,
    width: 55,
    alignItems: "center",
    borderRadius: "100%",
    boxShadow: "1px 1px 10px 0 #8250ff",
    "& svg": {
      width: 40,
      height: 40,
      color: "white",
    },
  },
  containerData: {
    display: "flex",
    gap: "2rem",
    marginLeft: "3rem",
    width: "100%",
    "& > div": {
      cursor: "pointer",
      width: "100%",
    },
  },
  return: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color:theme.palette.text.secondary,
    "& img": {
      width: 50,
      height: 50,
    },
  },
  cardDeposit: {
    gridArea: "cardDeposit",
    background: "white",
    boxShadow: "1px 1px 30px 0 grey",
    width: 480,
    height: 522,
    borderRadius: 30,
    padding: "33px 47px",
    "& > .title": {
      color: theme.palette.text.primary,
      fontSize: 24,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      paddingBottom: 20,
    },
    "& >div": {
      display: "flex",
      justifyContent: "space-between",
      lineHeight: 2.3,
      fontWeight: 500,
    },
    "& .button": {
      marginTop: 106,
    },
  },
  advertence: {
    gridArea: "advertence",
    width: 400,
    fontWeight: 600,
    marginTop: 73,
    marginLeft: 30,
  },
  deposit: {
    display: "flex",
    flexFlow: "column",
    gridArea: "deposit",
    background: "white",
    boxShadow: "1px 1px 30px 0 grey",
    width: 443,
    height: 196,
    borderRadius: 30,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    "& >span": {
      display: "block",
      fontWeight: "bold",
      fontSize: 24,
      paddingBottom: "1rem",
    },
    "& button": {
      fontSize: 20,
      fontWeight: 500,
      width: 250,
    },
  },
  invest: {
    gridArea: "invest",
    fontSize: 24,
    lineHeight: 1.33,
    width: 363,
    margin: "50px 35px",
    fontWeight: 300,
    "& strong": {
      fontWeight: "bold",
    },
  },
  lastBox: {
    gridArea: "lastBox",
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: "-3px -3px 30px 0 grey",
    width: 443,
    height: 270,
    borderRadius: 30,
    padding: 30,
    display: "grid",
    "&>div": {
      "& img": {
        width: 32,
        height: 32,
      },
      display: "flex",
      fontWeight: 300,
      alignItems: "center",
      gap: 28,
      width: 370,
      color: theme.palette.text.secondary,
    },
  },
  firstColumn: {
    paddingRight: 65,
  },
}));
